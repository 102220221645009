export const FEEDBACK_LIST_HEADERS = [
  {text: '#', value: 'index', class: 'fe-index', sortable: false},
  {text: 'Course', value: 'course', class: 'fe-course', sortable: true},
  {text: 'Candidate', value: 'candidate', class: 'fe-candidate', sortable: false},
  {text: 'Exam', value: 'exam', class: 'fe-exam', sortable: false},
  {text: 'Course leaders', value: 'course-leaders', class: 'fe-course-leaders', sortable: false},
  {text: 'Cursus rating', value: 'rating', class: 'fe-rating', sortable: true},
  {text: 'Cursus comment', value: 'comment', class: 'fe-comment', sortable: false},
  {text: 'Cbr exam rating', value: 'exam_rating', class: 'fe-cbr-exam-rating', sortable: true},
  {text: 'Cbr exam comment', value: 'exam_comment', class: 'fe-cbr-exam-comment', sortable: false},
  {text: 'Cursusleader rating', value: 'leader_rating', class: 'fe-leader-rating', sortable: true},
  {text: 'Cursusleader comment', value: 'leader_comment', class: 'fe-leader-comment', sortable: false},
  {text: 'Created at', value: 'created-at', class: 'fe-created-at', sortable: false},
  {text: 'Change status', value: 'change_status', class: 'fe-actions', sortable: false}
];

export const JUMBOTRON_FILTERS = {
  COURSE_DATE_BEFORE: 'course_date_before',
  COURSE_DATE_AFTER: 'course_date_after',
  CITY: 'city',
  COURSE_LEADER: 'course_leader'
};

export const FEEDBACK_STATUS_LIST = [
  {name: 'Unprocessed', value: 'UNPROCESSED'},
  {name: 'Good closed', value: 'GOOD_CLOSED'},
  {name: 'Bad closed', value: 'BAD_CLOSED'}
];

export const REAPPLY_APPLICATIONS_TYPES_LIST = {
  NO_PICK_UP: {label: 'Didn\'t pick up', value: 'NO_PICK_UP'},
  CALL_BACK: {label: 'Call back', value: 'CALL_BACK'},
  WILL_REAPPLY: {label: 'Will reapply', value: 'WILL_REAPPLY'},
  REAPPLIED: {label: 'Reapplied', value: 'REAPPLIED'},
  CANCELED: {label: 'Canceled', value: 'CANCELED'},
  OTHER: {label: 'Other', value: 'OTHER'}
};

export const FEEDBACK_LIST_TABS = {
  UNPROCESSED: 'UNPROCESSED',
  GOOD_CLOSED: 'GOOD_CLOSED',
  BAD_CLOSED: 'BAD_CLOSED'
};

import auth from '@/services/CRMinitAuth';

const list = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}feedback_task_list/offline/`, {
    params: query
  });
};

const patch = (examID, data) => {
  return auth.patch(`${process.env.VUE_APP_CRM_API}feedback_task_list/offline/${examID}/`, data);
};

const addLog = (id, comment) => {
  return auth.post(`${process.env.VUE_APP_CRM_API}feedback_task_list/offline/${id}/add_log/`, comment);
};

const updateStatus = (data) => {
  return auth.post(`${process.env.VUE_APP_CRM_API}feedback_task_list/offline/change_status/`, data);
};

export default {
  list,
  patch,
  addLog,
  updateStatus
};

<template lang="pug">
  .page-container.feedback-tasks
    portal(to="toolbar-title")
      toolbar-title Feedback task list

    .page-container__block
      search-widget(@change="loadList")

    .page-container__block
      filters(:loading="loading")

    .page-container__block
      .d-flex
        tabs(@change="changeTab" :counts="counts")
      .d-flex
        pagination(:pagination="pagination" :loading="loading" @change="loadList")

    .page-container__list
      list(
        ref="list"
        :headers="headers"
        :list="list"
        :loading="loading"
        :pagination="pagination"
        :primaryPagination="primaryPagination"
        @loadMore="loadMore"
        @change:sorting="updateSorting"
        @change:status="updateTaskStatus"
        @needToUpdate="loadList"
      )
</template>

<script>
import api from '@/services/api/api-feedback'
import FeedbackService from './core/feedback-service'

import appSelect from '@/components/global/fields/appSelect.vue'

import PaginationModel from '@/models/paginationModel'
import paginationMixin from '@/mixins/pagination.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

import {
  FEEDBACK_LIST_HEADERS,
  FEEDBACK_LIST_TABS
} from './core/feedback-const'

export default {
  mixins: [paginationMixin, errorsMixin, featureFlagsMixin],

  data: () => ({
    feedbackService: new FeedbackService(api),
    pagination: {},
    primaryPagination: {},
    list: [],
    loading: false,
    headers: FEEDBACK_LIST_HEADERS,
    counts: {
      [FEEDBACK_LIST_TABS.UNPROCESSED]: '...',
      [FEEDBACK_LIST_TABS.GOOD_CLOSED]: '...',
      [FEEDBACK_LIST_TABS.BAD_CLOSED]: '...'
    }
  }),

  async mounted() {
    await this.setDefaultPagination()
    await this.preSelectPageData()
    await this.loadList()
  },

  methods: {
    async changeTab() {
      this.clear()
      await this.resetList()
      await this.loadList()
    },

    resetList() {
      this.$refs.list.reset()
    },

    async loadList(more = false, query) {
      try {
        this.loading = true
        let {results, pagination, counts} = await this.feedbackService.list({...this.$route.query, ...query})
        if (more) this.list.push(...results)
        else this.list = results
        await this.$nextTick()
        this.counts = counts
        this.pagination = new PaginationModel(this.setPaginationParams(pagination))
        if (!more) this.setPrimaryPagination()
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async updateCounters() {
      try {
        this.loading = true
        let {counts} = await this.feedbackService.list(this.$route.query)
        await this.$nextTick()
        this.counts = counts
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async loadMore() {
      if (!this.pagination) return
      await this.onLoadMoreClick()
      await this.loadList(true)
    },

    setPrimaryPagination() {
      this.primaryPagination = { page: this.pagination.page, size: this.pagination.size }
    },

    async updateSorting(ordering) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ordering: ordering}})
      await this.loadList()
    },

    updateTaskStatus(id) {
      let index = this.list.findIndex(item => item.id === id)
      if (index < 0) return this.$notify({type: 'warning', text: 'Something wrong. Refresh page'})
      this.list.splice(index, 1)
      this.updateCounters()
    },

    updateItem(ID, field, data) {
      let itemIndex = this.list.findIndex(item => item.id === ID)
      if (itemIndex < 0) return
      this.list[itemIndex][field] = data
    },

    async setQuery(data, load) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...data}})
      if (load) await this.loadList()
    },

    async preSelectPageData() {
      if (!this.$route.query.ordering) await this.setQuery({ordering: '-datetime'}, false)
      if (!this.$route.query.tab) await this.setQuery({tab: FEEDBACK_LIST_TABS.UNPROCESSED}, false)
    },

    clear() {
      this.list = []
    }
  },

  provide: function () {
    return {
      feedbackService: this.feedbackService,
      getDefaultPagination: this.getDefaultPagination,
      getDefaultPageSize: this.getDefaultPageSize,
      updateItem: this.updateItem,
      setQuery: this.setQuery
    }
  },

  components: {
    appSelect,
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    filters: () => import('./components/FeedbackFilters.vue'),
    searchWidget: () => import('@/components/widget/SearchWidget.vue'),
    pagination: () => import('@/components/widget/PaginationWidget.vue'),
    tabs: () => import('./components/FeedbackTabs.vue'),
    list: () => import('./components/FeedbackList.vue')
  }
}
</script>